<template>
  <section>
    <div class="container">
      <h2 class="pb-3 border-bottom mb-5 fw-bold text-uppercase">{{mainContent.title}} </h2>
      <div class="row">
        <div class="col-sm-8">
          <p class="lead">{{mainContent.description}} <b> {{mainContent.bold}}</b></p>
        </div>
        <div class="col-sm-4">
          <div class="card bg-light mb-3">
            <div class="card-header">{{secondaryContent.title}}</div>
            <div class="card-body">
              <h5 class="card-title">{{secondaryContent.subtitle}}</h5>
              <p class="card-text">
                {{secondaryContent.description}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <OurReviews />
    </div>

    <!-- <div class="container pb-5 mt-5">
      <div class="card mb-3">
        <CaruselSlides />
      </div>
    </div> -->

    <CallToAction />
  </section>
</template>

<script>
import CallToAction from './CallToAction.vue'
import OurReviews from './OurReviews.vue'
// import CaruselSlides from './CaruselSlides.vue'

export default {
  name: 'AboutCompany',

  components: {
    CallToAction,
    OurReviews
    // CaruselSlides
  },
  data () {
    return {
      mainContent: {
        title: 'О компании',
        description: 'Мы – молодая компания, обладающая большой материально-технической базой и опытом в области работы с жестью. Наше основное преимущество – это принцип работы: «как для себя»! Производство водосточных систем, любых гнутых элементов, полный комплект изделий из жести для строительства и отделки и вообще все, что связано с металлом. Универсальность и гибкость производства. Короткие сроки. Работаем 24 / 7. Осуществляем доставку собственным транспортом. Так же предлагаем широкий спектр услуг по обслуживанию Вашей загородной недвижимости (обслуживаем кровли, дымоходы, моем фасады, убираем снег и наледь, коронируем и валим деревья частями). Выезжаем на замеры. Изготавливаем точные копии водосточной продукции таких компаний как ',
        bold: 'Orima, Ruukki, Rainman, Aquasystem, Gamrat'
      },
      secondaryContent: {
        title: 'Монтируем все, что производим',
        subtitle: 'Никакого аутсорсинга – все делаем сами!',
        description: 'Мы – сертифицированные жестянщики с большим опытом работы, поэтому рады предложить вам услуги по любому монтажу наших изделий, включая монтаж на высоте (в штате есть промышленные альпинисты с необходимыми допусками и документами)'
      }
    }
  }
}
</script>

<style>

</style>
