<template>
  <div class="bg-dark text-secondary px-4 py-5 text-center">
              <div class="py-5">
                  <h3 class="display-5 fw-bold text-white">{{title}}</h3>
                  <div class="col-lg-6 mx-auto">
                      <p class="fs-5 mb-4">{{description}}</p>
                      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                          <a
                          v-for="button in buttons"
                          :key="button.title"
                          :class="button.classes"
                          :href="button.link"
                          >
                            {{button.title}}
                          </a>
                      </div>
                  </div>
              </div>
          </div>
</template>

<script>
export default {
  name: 'CallToAction',
  data () {
    return {
      title: 'Вот поэтому стоит обратится к нам',
      description: 'Гибкость, клиентоориентированность, готовность поработать ночью, если заказ «нужен был вчера» 😉',
      buttons: [
        {
          title: 'Написать',
          classes: 'btn btn-outline-info btn-lg px-4 me-sm-3 fw-bold',
          link: `mailto:${this.$contacts.email}`
        },
        {
          title: 'Позвонить',
          classes: 'btn btn-outline-light btn-lg px-4',
          link: `tel:${this.$contacts.phone}`
        }
      ]
    }
  }
}
</script>

<style>

</style>
