<template>
  <div id="app">
    <HeaderApp />
    <main>
      <router-view/>
    </main>
    <ContactForm
    v-if="$route.path !== '/items/products-from-tin'"
    />
    <FooterApp />
    <Cart/>
  </div>
</template>

<script>
import HeaderApp from './components/HeaderApp.vue'
import ContactForm from '@/components/ContactForm.vue'
import FooterApp from '@/components/FooterApp.vue'
import Cart from '@/components/Cart.vue'
import './assets/css/bootstrap.min.css'
import './assets/css/main.css'
import './assets/js/bootstrap.bundle.min.js'
// import './assets/js/index.js'

export default {
  name: 'App-component',

  components: {
    HeaderApp,
    ContactForm,
    FooterApp,
    Cart
  },

  created () {
    if (localStorage.getItem('vdsk78')) {
      this.$store.commit('addAsItIs', JSON.parse(localStorage.getItem('vdsk78')))
    }
  }
}
</script>
<style>
#app {
  min-height: 100vh;
  position: relative;
}
a[href='/politika'] {
  text-decoration: none;
}
</style>
