<template>
  <footer class="main-footer text-center text-white bg-dark">
    <div class="container p-4">
      <section class="mb-4">

        <!-- <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"><i class="fab fa-vk"></i></a> -->

        <a class="btn btn-outline-light btn-floating m-1" :href="`https://wa.me/${$contacts.wa}`" role="button"><i
            class="fab fa-whatsapp"></i></a>

        <a class="btn btn-outline-light btn-floating m-1" :href="`tg://resolve?domain=${$contacts.telegram}`" role="button"><i
            class="fab fa-telegram-plane"></i></a>

        <!-- <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"><i class="fab fa-instagram"></i></a> -->
      </section>
    </div>

    <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
      © <span id="copyrightYear"> {{new Date().getFullYear()}} </span>
      <span class="text-white">Vodostok78</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterApp'

}
</script>

<style>
</style>
