<template>
  <header
  :class="{headerHome: activeTab == 'Home'}"
  class="main-header mb-5">
    <!-- <div class="new-year-decor">
      <img src="../assets/img/ded.webp" alt="" width="400" height="258">
    </div> -->
        <nav class="navbar navbar-expand-lg navbar-light mx-xl-3">
            <div class="container-fluid">
                <router-link
                class="navbar-brand" to="/" exact>
                    <img src="../assets/img/logo.svg" alt="" width="200" height="auto">
                </router-link>
                <button
                class="navbar-toggler custom-toggler ml-2"
                type="button"
                data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse ml-2 justify-content-between" id="navbarSupportedContent">
                    <ul class="navbar-nav mt-3 mb-lg-0 ">
                        <li class="nav-item">
                            <router-link
                            class="nav-link text-light" to="/" exact>
                                Главная
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                            class="nav-link text-light" to="/items">
                                Продукция
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                            class="nav-link text-light" to="/services">
                                Услуги
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-light cartOpen position-relative" href="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                              Смета
                              <span
                              v-if="$store.state.cart.length > 0"
                              class="badge bg-secondary"
                              style="font-size: 14px">
                              {{$store.state.cart.length}}
                              </span>
                            </a>
                        </li>
                    </ul>
                    <div
                    class="gy-3 row row-cols-1 contacts-header mr-3 mt-5 mt-lg-0">
                        <a class="btn btn-primary fs-5" :href="`tel:${$contacts.phone}`">{{$contacts.phone}}</a>
                        <a class="btn btn-dark fs-5" :href="`mailto:${$contacts.email}`">{{$contacts.email}}</a>
                        <a class="btn btn-light fs-5 d-lg-none" href="yandexnavi://build_route_on_map?lat_to=59.950596&lon_to=30.553329">Янино-1, ул. Кольцевая, д. 1Б</a>
                        <a class="btn btn-light fs-5 d-none d-lg-block" href="https://yandex.ru/maps/?rtext=~59.950596,30.553329&rtt=auto" target="_blank">Янино-1, ул. Кольцевая, д. 1Б</a>
                    </div>
                </div>
            </div>
        </nav>

        <div
        class="px-4 text-center hidenHero"
        :class="{visibleHero: activeTab == 'Home', 'py-5': activeTab == 'Home', 'my-5': activeTab == 'Home'}">
            <h1 class="display-5 fw-bold text-light black-text-shadow" style="position: relative; z-index: 1"><span>{{hero.title}}</span></h1>
            <div class="col-lg-6 mx-auto">
                <!-- <p class="lead mb-4 text-light black-text-shadow">{{hero.description}}</p> -->
                <div class="d-grid gap-2 d-sm-flex justify-content-sm-center mt-5">
                  <router-link
                  to="/services"
                  class="btn btn-primary btn-lg px-4 gap-3">
                  Услуги
                  </router-link>
                  <button
                  type="button"
                  @click.prevent="$router.push('items')"
                  class="btn btn-dark btn-lg px-4">
                  Каталог
                  </button>
                </div>
            </div>
        </div>
        <div class="container mt-4 pb-3 d-none d-lg-block">
              <div class="row">
                <div
                v-for="(item, i) in keyIndicators"
                :key="i"
                :class="item.class"
                class="col text-light text-start lead fw-bold key-indicator black-text-shadow">
                {{item.title}}
                </div>
              </div>
          </div>

        <div class="modal fade" id="modal2" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Заказ услуг</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="закрыть"></button>
              </div>
              <div class="modal-body">
                <Alert
                v-if="alert"
                :status="status"/>
                <form
                ref="form_in_header"
                >
                <input type="hidden" name="type" value="header"/>
                  <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Услуга:</label>
                    <input
                    name="service"
                    type="text"
                    class="form-control"
                    id="nameServices">
                  </div>
                  <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Ваше имя:</label>
                    <input
                    name="name"
                    type="text"
                    class="form-control"
                    id="clientName">
                  </div>
                  <div class="mb-3">
                    <label for="recipient-name" class="col-form-label">Как связаться (телефон или почта)</label>
                    <input
                    v-model="contact"
                    name="contact"
                    type="text"
                    class="form-control"
                    id="contact"
                    required>
                  </div>
                  <div class="mb-3">
                    <label for="message-text" class="col-form-label">Сообщение:</label>
                    <textarea
                    name="text"
                    class="form-control"
                    id="message-text"></textarea>
                  </div>
                  <small class="text-muted">Нажимая кнопку <b>'отправить'</b> вы соглашаетесь с нашей <router-link
                to="/politika" target="_blank">Политикой конфиденциальности</router-link></small>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
                <button
                :disabled="contact.length < 5 || sending"
                @click.prevent="submitForm"
                type="submit"
                class="btn"
                :class="contact && contact.length >= 5 ? 'btn-primary' : 'btn-secondary'"
                >
                {{contact && contact.length >= 5 ? 'Отправить' : 'Для отправки заполните контактную форму'}}</button>
              </div>
            </div>
          </div>
        </div>
        <!-- v-if="isShowInfo" -->
        <div v-if="false" class="infoBlock d-flex justify-content-between flex-wrap align-items-center bg-danger text-light">
          <div></div>
          <div>
            <span class="h4 ">Держим цены! </span>
            <span class="h4"> До конца марта цены 2023 года</span>
          </div>
          <button type="button" class="btn-close ml-auto p-3" aria-label="Закрыть" @click="isShowInfo=false"></button>
        </div>
  </header>
</template>

<script>
import Alert from './Alert.vue'

export default {
  name: 'HeaderApp',

  components: {
    Alert
  },

  data () {
    return {
      isShowInfo: true,
      contact: '',
      sending: false,
      status: '',
      alert: false,
      hero: {
        title: 'Водосточные системы, изделия из жести, высотные работы',
        description: ''
      },
      keyIndicators: [
        { title: 'Режим работы 24 / 7', class: 'key-indicator__time' },
        { title: 'Широкий спектр услуг', class: 'key-indicator__list' },
        { title: 'Универсальность и гибкость производства', class: 'key-indicator__flex' }
      ]
    }
  },

  methods: {
    submitForm: async function () {
      this.sending = true
      const response = await fetch('/mailer/send.php', {
        method: 'POST',
        body: new FormData(this.$refs.form_in_header)
      })

      if (response.status === 200) {
        this.$refs.form_in_header.reset()
        this.status = 'success'
        this.alert = true
        this.contact = ''
        setTimeout(() => {
          this.status = ''
          this.alert = false
        }, 3000)
      } else {
        this.status = 'error'
        this.alert = true
        setTimeout(() => {
          this.status = ''
          this.alert = false
        }, 3000)
      }
      this.sending = false
    }
  },

  computed: {
    activeTab () {
      return this.$route.name
    }
  }
}
</script>

<style>
.new-year-decor {
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  z-index: 0;
}
.new-year-decor img {
  max-width: 100%;
}
  .router-link-active {
    border-bottom: 1px solid;
  }

  .nav-link:hover {
    opacity: 0.7;
  }

  header.headerHome {
    transition: all, 0.3s;
    background-image: url(../assets/img/header-background-2.webp);
    background-color: #2e2b2b;
    /* min-height: 600px; */
  }

  .hidenHero {
    transition: all, 0.3s;
    height: 1px;
    opacity: 0;
    margin: 0;
    padding: 0;
  }
  .visibleHero {
    transition: all, 0.3s;
    height: auto;
    opacity: 1;
    min-height: 400px;
  }

  .key-indicator {
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: 0 0;
    padding-left: 50px!important;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .key-indicator__flex {
    background-image: url(../assets/img/shuffle.svg);
  }

  .key-indicator__time {
    background-image: url(../assets/img/time.svg);
  }

  .key-indicator__list {
    background-image:  url(../assets/img/list_long.svg);
  }

  .navbar-brand {
    border: none;
  }

  .navbar-brand:hover {
    opacity: 0.8;
  }

  .navbar-brand:active {
    transform: scale(0.9);
  }
</style>
