<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
    aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
      <h5 id="offcanvasRightLabel">Смета</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
        aria-label="Close"></button>
    </div>
    <div class="offcanvas-body cart">
      <h5
      v-if="$store.state.cart.length === 0"
      >В смете ничего нет</h5>
      <Alert
      v-if="alert"
      :status="status"/>
      <form
      ref="cart">
      <input type="hidden" name="type" value="cart">
      <table
      v-if="$store.state.cart.length > 0"
      class="table table-striped">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Название</th>
            <th scope="col">Цена</th>
            <th scope="col">Количество</th>
            <th scope="col">Стоимость</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
          v-for="(item, i) in $store.state.cart"
          :key="i"
          >
            <th scope="row">{{i + 1}}</th>
            <td>{{item.title}} <br/> {{item.prop.prop + ' ' + item.ral}}</td>
            <input type="hidden" :name="`item[${i}][title]`" :value="item.title">
            <input type="hidden" :name="`item[${i}][prop]`" :value="item.prop.prop + ' ' + item.ral">
            <td>{{item.price}}</td>
            <input type="hidden" :name="`item[${i}][price]`" :value="item.price">
            <td>
              <button
              type="button"
              class="miniBtn"
              :style="{'backgroundImage': `url(${removeIcon})`}"
              @click.prevent="$store.commit('removeFromCart', {title: item.title, prop: item.prop, ral: item.ral, price: item.price})"
              >
              </button>
              <InputCount
              :nameAtr="`item[${i}][count]`"
              :id="{title: item.title, prop: item.prop, ral: item.ral, price: item.price}"/>
              <!-- <input type="hidden" :name="`item[${i}][count]`" :value="item.count"> -->
              <button
              type="button"
              class="miniBtn"
              :style="{'backgroundImage': `url(${addIcon})`}"
              @click.prevent="$store.commit('addToCart', {title: item.title, prop: item.prop, ral: item.ral, price: item.price})"
              >
              </button>
            </td>
            <td>
              {{item.count * item.price}}
            </td>
            <td>
              <button
              type="button"
              class="miniBtn"
              :style="{'backgroundImage': `url(${deleteIcon})`}"
              @click.prevent="$store.commit('removeItem', {title: item.title, prop: item.prop, ral: item.ral, price: item.price})"
              >
              </button>
            </td>
            <input type="hidden" :name="`item[${i}][total]`" :value="item.count * item.price">
          </tr>
        </tbody>
        <tfoot>
          <td colspan="5">Итого</td>
          <td><b>{{$store.state.total}}</b></td>
          <input type="hidden" name="total" :value="$store.state.total">
        </tfoot>
      </table>

      <div
      v-if="$store.state.cart.length > 0"
      >
        <h5>Для заказа заполните форму</h5>

            <div class="mb-3">
              <label for="inputName" class="form-label">Имя или название компании</label>
              <input type="text" class="form-control" id="inputName" autocomplete="false" name="name">
            </div>
            <div class="mb-3">
              <label for="inputContact" class="form-label">Телефон или email</label>
              <input
              v-model="contact"
              type="text"
              class="form-control"
              id="inputContact"
              required
              name="contact">
            </div>
            <div class="mb-3">
              <label for="formFileMultiple" class="form-label">Приложите файл</label>
              <input class="form-control" type="file" id="formFileMultiple" name="myfile" accept="image/*, video/*, text/plain, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, .xml, .json">
            </div>
            <div class="mb-3 form-check">
              <small class="text-muted">Нажимая кнопку <b>'отправить'</b> вы соглашаетесь с нашей <router-link
              to="/politika" target="_blank">Политикой конфиденциальности</router-link></small>
            </div>
            <button
            :disabled="contact.length < 5 || sending"
            @click.prevent="submitForm"
            type="submit"
            class="btn"
            :class="contact && contact.length >= 5 ? 'btn-primary' : 'btn-secondary'"
            >
            {{contact && contact.length >= 5 ? 'Отправить' : 'Для отправки заполните контактную форму'}}</button>
      </div>
      </form>
    </div>
  </div>
</template>

<script>
import InputCount from './InputCount.vue'
import Alert from './Alert.vue'

export default {
  name: 'Cart',
  components: {
    InputCount,
    Alert
  },
  data () {
    return {
      contact: '',
      sending: false,
      status: '',
      alert: false,
      addIcon: require('../assets/img/new/add.svg'),
      removeIcon: require('../assets/img/new/remove.svg'),
      deleteIcon: require('../assets/img/new/delete.svg')
    }
  },

  methods: {
    submitForm: async function () {
      this.sending = true
      const response = await fetch('/mailer/send.php', {
        method: 'POST',
        body: new FormData(this.$refs.cart)
      })
      if (response.status === 200) {
        this.$refs.cart.reset()
        this.$store.commit('removeFromCartAll', [])
        this.status = 'success'
        this.alert = true
        setTimeout(() => {
          this.status = ''
          this.alert = false
        }, 3000)
      } else {
        this.status = 'error'
        this.alert = true
        setTimeout(() => {
          this.status = ''
          this.alert = false
        }, 3000)
      }
      this.sending = false
    }
  }
}
</script>

<style>
.miniBtn {
  border-radius: 50%;
  padding: 0;
  width: 25px;
  height: 25px;
  background-position: center;
  background-size: cover;
  border: none;
  vertical-align: middle;
  background-color: transparent;
}

.miniBtn:hover {
  opacity: 0.7;
}

.miniBtn:active {
  transform: scale(0.9)
}

</style>
