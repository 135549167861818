import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    cart: [],
    get total () {
      let totalPrice = 0
      this.cart.forEach((cartItem) => {
        totalPrice += cartItem.price * cartItem.count
      })
      return totalPrice
    }
  },
  mutations: {
    addToCart (state, value) {
      const findEl = state.cart.find(item => value.title + Object.values(value.prop).join(',') + value.ral === item.title + Object.values(item.prop).join(',') + item.ral)
      if (!findEl) {
        value.count = 1
        state.cart.push(value)
      } else {
        findEl.count++
      }

      localStorage.setItem('vdsk78', JSON.stringify(state.cart))
    },

    removeFromCart (state, value) {
      const findInd = state.cart.findIndex(item => value.title + Object.values(value.prop).join(',') + value.ral === item.title + Object.values(item.prop).join(',') + item.ral)
      if (state.cart[findInd].count > 1) {
        state.cart[findInd].count--
      } else {
        state.cart.splice(findInd, 1)
      }

      localStorage.setItem('vdsk78', JSON.stringify(state.cart))
    },

    updateCart (state, value) {
      const findInd = state.cart.findIndex(item => value.id.title + Object.values(value.id.prop).join(',') + value.id.ral === item.title + Object.values(item.prop).join(',') + item.ral)
      if (value.value > 0) {
        state.cart[findInd].count = value.value
      } else {
        state.cart[findInd].count = 1
        // state.cart.splice(findInd, 1)
      }

      localStorage.setItem('vdsk78', JSON.stringify(state.cart))
    },

    removeFromCartAll (state, value) {
      state.cart = value
      localStorage.setItem('vdsk78', JSON.stringify(state.cart))
    },

    removeItem (state, value) {
      const findInd = state.cart.findIndex(item => value.title + Object.values(value.prop).join(',') + value.ral === item.title + Object.values(item.prop).join(',') + item.ral)
      state.cart.splice(findInd, 1)
      localStorage.setItem('vdsk78', JSON.stringify(state.cart))
    },

    addAsItIs (state, value) {
      value.forEach(item => state.cart.push(item))
    }
  },
  actions: {
  },
  modules: {
  }
})
