<template>
  <section>
    <div class="container py-5">
      <h2 class="pb-3 border-bottom fw-bold text-uppercase">Преимущества</h2>
      <div class="container" id="custom-cards">
        <div
        v-for="advantage in advantages"
        :key="advantage.id"
        class="row featurette border-bottom py-5">
          <div
          :style="{order: advantage.id % 2}"
          class="col-md-7 align-self-center">
            <h2 class="featurette-heading">{{advantage.title}}<span class="text-muted">{{advantage.subtitle}}</span>
            </h2>
            <p class="lead">{{advantage.description}}</p>
          </div>
          <div class="col-md-5">
            <vueper-slides 3d fixed-height="500px" :arrows-outside="false">
              <vueper-slide
              v-for="(img, i) in advantage.imgs"
              :key="i"
              :image="img"/>
            </vueper-slides>
            <!-- <img
            :src="advantage.img"
            class="rounded img-thumbnail"
            width="100%"
            height="auto"
            :alt="advantage.title"> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import utils from '../utils.js'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'OurAdvantages',
  components: {
    VueperSlides, VueperSlide
  },
  data () {
    return {
      advantages: [
        {
          title: 'Мы в теме ',
          subtitle: 'и говорим на одном языке с Вами',
          description: 'Поскольку мы не только изготавливаем металлоизделия, но и выполняем их монтаж, то имеем понимание о том, как оптимально изготовить нашу продукцию',
          imgs: [
            require('../assets/img/new/feature1.webp'),
            require('../assets/img/new/product1.webp'),
            require('../assets/img/new/product2.webp'),
            require('../assets/img/new/product3.webp')
          ],
          id: 1
        },
        {
          title: 'Оснащённость. ',
          subtitle: 'Упакованы по полной',
          description: 'Обладаем станочным парком, позволяющим выполнить любой Ваш каприз. Даже длинный. Но постарайтесь не капризничать на длину более 3 метров',
          imgs: [utils.getUrlImg('677_original.webp')],
          id: 2
        },
        {
          title: 'Вам везёт ...',
          subtitle: 'VODOSTOK78!',
          description: 'Доставка собственным транспортом, по разумной цене и в самые сжатые сроки. Вам не придется беспокоиться о логистике',
          imgs: [utils.getUrlImg('XXL.webp')],
          id: 3
        }
      ]
    }
  }
}
</script>

<style>

</style>
