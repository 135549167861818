<template>
    <input
      :name="nameAtr"
      v-model="count"
      style="border: 1px solid #5079f3; border-radius: 2px; max-width: 40px; margin: 0 5px; text-align: center"/>
</template>

<script>
export default {
  name: 'InputCount',
  props: ['id', 'nameAtr'],
  computed: {
    count: {
      get () {
        return this.$store.state.cart.find(item => this.id.title + Object.values(this.id.prop).join(',') + this.id.ral === item.title + Object.values(item.prop).join(',') + item.ral).count
      },
      set (value) {
        this.$store.commit('updateCart', { id: this.id, value: value })
      }
    }
  }
}
</script>
