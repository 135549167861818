const color = [{
  RAL: 'RAL 1000',
  HEX: '#BEBD7F'
}, {
  RAL: 'RAL 1001',
  HEX: '#C2B078'
}, {
  RAL: 'RAL 1002',
  HEX: '#C6A664'
}, {
  RAL: 'RAL 1003',
  HEX: '#E5BE01'
}, {
  RAL: 'RAL 1004',
  HEX: '#CDA434'
}, {
  RAL: 'RAL 1005',
  HEX: '#A98307'
}, {
  RAL: 'RAL 1006',
  HEX: '#E4A010'
}, {
  RAL: 'RAL 1007',
  HEX: '#DC9D00'
}, {
  RAL: 'RAL 1011',
  HEX: '#8A6642'
}, {
  RAL: 'RAL 1012',
  HEX: '#C7B446'
}, {
  RAL: 'RAL 1013',
  HEX: '#EAE6CA'
}, {
  RAL: 'RAL 1014',
  HEX: '#E1CC4F'
}, {
  RAL: 'RAL 1015',
  HEX: '#E6D690'
}, {
  RAL: 'RAL 1016',
  HEX: '#EDFF21'
}, {
  RAL: 'RAL 1017',
  HEX: '#F5D033'
}, {
  RAL: 'RAL 1018',
  HEX: '#F8F32B'
}, {
  RAL: 'RAL 1019',
  HEX: '#9E9764'
}, {
  RAL: 'RAL 1020',
  HEX: '#999950'
}, {
  RAL: 'RAL 1021',
  HEX: '#F3DA0B'
}, {
  RAL: 'RAL 1023',
  HEX: '#FAD201'
}, {
  RAL: 'RAL 1024',
  HEX: '#AEA04B'
}, {
  RAL: 'RAL 1026',
  HEX: '#FFFF00'
}, {
  RAL: 'RAL 1027',
  HEX: '#9D9101'
}, {
  RAL: 'RAL 1028',
  HEX: '#F4A900'
}, {
  RAL: 'RAL 1032',
  HEX: '#D6AE01'
}, {
  RAL: 'RAL 1033',
  HEX: '#F3A505'
}, {
  RAL: 'RAL 1034',
  HEX: '#EFA94A'
}, {
  RAL: 'RAL 1035',
  HEX: '#6A5D4D'
}, {
  RAL: 'RAL 1036',
  HEX: '#705335'
}, {
  RAL: 'RAL 1037',
  HEX: '#F39F18'
}, {
  RAL: 'RAL 2000',
  HEX: '#ED760E'
}, {
  RAL: 'RAL 2001',
  HEX: '#C93C20'
}, {
  RAL: 'RAL 2002',
  HEX: '#CB2821'
}, {
  RAL: 'RAL 2003',
  HEX: '#FF7514'
}, {
  RAL: 'RAL 2004',
  HEX: '#F44611'
}, {
  RAL: 'RAL 2005',
  HEX: '#FF2301'
}, {
  RAL: 'RAL 2007',
  HEX: '#FFA420'
}, {
  RAL: 'RAL 2008',
  HEX: '#F75E25'
}, {
  RAL: 'RAL 2009',
  HEX: '#F54021'
}, {
  RAL: 'RAL 2010',
  HEX: '#D84B20'
}, {
  RAL: 'RAL 2011',
  HEX: '#EC7C26'
}, {
  RAL: 'RAL 2012',
  HEX: '#E55137'
}, {
  RAL: 'RAL 2013',
  HEX: '#C35831'
}, {
  RAL: 'RAL 3000',
  HEX: '#AF2B1E'
}, {
  RAL: 'RAL 3001',
  HEX: '#A52019'
}, {
  RAL: 'RAL 3002',
  HEX: '#A2231D'
}, {
  RAL: 'RAL 3003',
  HEX: '#9B111E'
}, {
  RAL: 'RAL 3004',
  HEX: '#75151E'
}, {
  RAL: 'RAL 3005',
  HEX: '#5E2129'
}, {
  RAL: 'RAL 3007',
  HEX: '#412227'
}, {
  RAL: 'RAL 3009',
  HEX: '#642424'
}, {
  RAL: 'RAL 3011',
  HEX: '#781F19'
}, {
  RAL: 'RAL 3012',
  HEX: '#C1876B'
}, {
  RAL: 'RAL 3013',
  HEX: '#A12312'
}, {
  RAL: 'RAL 3014',
  HEX: '#D36E70'
}, {
  RAL: 'RAL 3015',
  HEX: '#EA899A'
}, {
  RAL: 'RAL 3016',
  HEX: '#B32821'
}, {
  RAL: 'RAL 3017',
  HEX: '#E63244'
}, {
  RAL: 'RAL 3018',
  HEX: '#D53032'
}, {
  RAL: 'RAL 3020',
  HEX: '#CC0605'
}, {
  RAL: 'RAL 3022',
  HEX: '#D95030'
}, {
  RAL: 'RAL 3024',
  HEX: '#F80000'
}, {
  RAL: 'RAL 3026',
  HEX: '#FE0000'
}, {
  RAL: 'RAL 3027',
  HEX: '#C51D34'
}, {
  RAL: 'RAL 3028',
  HEX: '#CB3234'
}, {
  RAL: 'RAL 3031',
  HEX: '#B32428'
}, {
  RAL: 'RAL 3032',
  HEX: '#721422'
}, {
  RAL: 'RAL 3033',
  HEX: '#B44C43'
}, {
  RAL: 'RAL 4001',
  HEX: '#6D3F5B'
}, {
  RAL: 'RAL 4002',
  HEX: '#922B3E'
}, {
  RAL: 'RAL 4003',
  HEX: '#DE4C8A'
}, {
  RAL: 'RAL 4004',
  HEX: '#641C34'
}, {
  RAL: 'RAL 4005',
  HEX: '#6C4675'
}, {
  RAL: 'RAL 4006',
  HEX: '#A03472'
}, {
  RAL: 'RAL 4007',
  HEX: '#4A192C'
}, {
  RAL: 'RAL 4008',
  HEX: '#924E7D'
}, {
  RAL: 'RAL 4009',
  HEX: '#A18594'
}, {
  RAL: 'RAL 4010',
  HEX: '#CF3476'
}, {
  RAL: 'RAL 4011',
  HEX: '#8673A1'
}, {
  RAL: 'RAL 4012',
  HEX: '#6C6874'
}, {
  RAL: 'RAL 5000',
  HEX: '#354D73'
}, {
  RAL: 'RAL 5001',
  HEX: '#1F3438'
}, {
  RAL: 'RAL 5002',
  HEX: '#20214F'
}, {
  RAL: 'RAL 5003',
  HEX: '#1D1E33'
}, {
  RAL: 'RAL 5004',
  HEX: '#18171C'
}, {
  RAL: 'RAL 5005',
  HEX: '#1E2460'
}, {
  RAL: 'RAL 5007',
  HEX: '#3E5F8A'
}, {
  RAL: 'RAL 5008',
  HEX: '#26252D'
}, {
  RAL: 'RAL 5009',
  HEX: '#025669'
}, {
  RAL: 'RAL 5010',
  HEX: '#0E294B'
}, {
  RAL: 'RAL 5011',
  HEX: '#231A24'
}, {
  RAL: 'RAL 5012',
  HEX: '#3B83BD'
}, {
  RAL: 'RAL 5013',
  HEX: '#1E213D'
}, {
  RAL: 'RAL 5014',
  HEX: '#606E8C'
}, {
  RAL: 'RAL 5015',
  HEX: '#2271B3'
}, {
  RAL: 'RAL 5017',
  HEX: '#063971'
}, {
  RAL: 'RAL 5018',
  HEX: '#3F888F'
}, {
  RAL: 'RAL 5019',
  HEX: '#1B5583'
}, {
  RAL: 'RAL 5020',
  HEX: '#1D334A'
}, {
  RAL: 'RAL 5021',
  HEX: '#256D7B'
}, {
  RAL: 'RAL 5022',
  HEX: '#252850'
}, {
  RAL: 'RAL 5023',
  HEX: '#49678D'
}, {
  RAL: 'RAL 5024',
  HEX: '#5D9B9B'
}, {
  RAL: 'RAL 5025',
  HEX: '#2A6478'
}, {
  RAL: 'RAL 5026',
  HEX: '#102C54'
}, {
  RAL: 'RAL 6000',
  HEX: '#316650'
}, {
  RAL: 'RAL 6001',
  HEX: '#287233'
}, {
  RAL: 'RAL 6002',
  HEX: '#2D572C'
}, {
  RAL: 'RAL 6003',
  HEX: '#424632'
}, {
  RAL: 'RAL 6004',
  HEX: '#1F3A3D'
}, {
  RAL: 'RAL 6005',
  HEX: '#2F4538'
}, {
  RAL: 'RAL 6006',
  HEX: '#3E3B32'
}, {
  RAL: 'RAL 6007',
  HEX: '#343B29'
}, {
  RAL: 'RAL 6008',
  HEX: '#39352A'
}, {
  RAL: 'RAL 6009',
  HEX: '#31372B'
}, {
  RAL: 'RAL 6010',
  HEX: '#35682D'
}, {
  RAL: 'RAL 6011',
  HEX: '#587246'
}, {
  RAL: 'RAL 6012',
  HEX: '#343E40'
}, {
  RAL: 'RAL 6013',
  HEX: '#6C7156'
}, {
  RAL: 'RAL 6014',
  HEX: '#47402E'
}, {
  RAL: 'RAL 6015',
  HEX: '#3B3C36'
}, {
  RAL: 'RAL 6016',
  HEX: '#1E5945'
}, {
  RAL: 'RAL 6017',
  HEX: '#4C9141'
}, {
  RAL: 'RAL 6018',
  HEX: '#57A639'
}, {
  RAL: 'RAL 6019',
  HEX: '#BDECB6'
}, {
  RAL: 'RAL 6020',
  HEX: '#2E3A23'
}, {
  RAL: 'RAL 6021',
  HEX: '#89AC76'
}, {
  RAL: 'RAL 6022',
  HEX: '#25221B'
}, {
  RAL: 'RAL 6024',
  HEX: '#308446'
}, {
  RAL: 'RAL 6025',
  HEX: '#3D642D'
}, {
  RAL: 'RAL 6026',
  HEX: '#015D52'
}, {
  RAL: 'RAL 6027',
  HEX: '#84C3BE'
}, {
  RAL: 'RAL 6028',
  HEX: '#2C5545'
}, {
  RAL: 'RAL 6029',
  HEX: '#20603D'
}, {
  RAL: 'RAL 6032',
  HEX: '#317F43'
}, {
  RAL: 'RAL 6033',
  HEX: '#497E76'
}, {
  RAL: 'RAL 6034',
  HEX: '#7FB5B5'
}, {
  RAL: 'RAL 6035',
  HEX: '#1C542D'
}, {
  RAL: 'RAL 6036',
  HEX: '#193737'
}, {
  RAL: 'RAL 6037',
  HEX: '#008F39'
}, {
  RAL: 'RAL 6038',
  HEX: '#00BB2D'
}, {
  RAL: 'RAL 7000',
  HEX: '#78858B'
}, {
  RAL: 'RAL 7001',
  HEX: '#8A9597'
}, {
  RAL: 'RAL 7002',
  HEX: '#7E7B52'
}, {
  RAL: 'RAL 7003',
  HEX: '#6C7059'
}, {
  RAL: 'RAL 7004',
  HEX: '#969992'
}, {
  RAL: 'RAL 7005',
  HEX: '#646B63'
}, {
  RAL: 'RAL 7006',
  HEX: '#6D6552'
}, {
  RAL: 'RAL 7008',
  HEX: '#6A5F31'
}, {
  RAL: 'RAL 7009',
  HEX: '#4D5645'
}, {
  RAL: 'RAL 7010',
  HEX: '#4C514A'
}, {
  RAL: 'RAL 7011',
  HEX: '#434B4D'
}, {
  RAL: 'RAL 7012',
  HEX: '#4E5754'
}, {
  RAL: 'RAL 7013',
  HEX: '#464531'
}, {
  RAL: 'RAL 7015',
  HEX: '#434750'
}, {
  RAL: 'RAL 7016',
  HEX: '#293133'
}, {
  RAL: 'RAL 7021',
  HEX: '#23282B'
}, {
  RAL: 'RAL 7022',
  HEX: '#332F2C'
}, {
  RAL: 'RAL 7023',
  HEX: '#686C5E'
}, {
  RAL: 'RAL 7024',
  HEX: '#474A51'
}, {
  RAL: 'RAL 7026',
  HEX: '#2F353B'
}, {
  RAL: 'RAL 7030',
  HEX: '#8B8C7A'
}, {
  RAL: 'RAL 7031',
  HEX: '#474B4E'
}, {
  RAL: 'RAL 7032',
  HEX: '#B8B799'
}, {
  RAL: 'RAL 7033',
  HEX: '#7D8471'
}, {
  RAL: 'RAL 7034',
  HEX: '#8F8B66'
}, {
  RAL: 'RAL 7035',
  HEX: '#CBD0CC'
}, {
  RAL: 'RAL 7036',
  HEX: '#7F7679'
}, {
  RAL: 'RAL 7037',
  HEX: '#7D7F7D'
}, {
  RAL: 'RAL 7038',
  HEX: '#B5B8B1'
}, {
  RAL: 'RAL 7039',
  HEX: '#6C6960'
}, {
  RAL: 'RAL 7040',
  HEX: '#9DA1AA'
}, {
  RAL: 'RAL 7042',
  HEX: '#8D948D'
}, {
  RAL: 'RAL 7043',
  HEX: '#4E5452'
}, {
  RAL: 'RAL 7044',
  HEX: '#CAC4B0'
}, {
  RAL: 'RAL 7045',
  HEX: '#909090'
}, {
  RAL: 'RAL 7046',
  HEX: '#82898F'
}, {
  RAL: 'RAL 7047',
  HEX: '#D0D0D0'
}, {
  RAL: 'RAL 7048',
  HEX: '#898176'
}, {
  RAL: 'RAL 8000',
  HEX: '#826C34'
}, {
  RAL: 'RAL 8001',
  HEX: '#955F20'
}, {
  RAL: 'RAL 8002',
  HEX: '#6C3B2A'
}, {
  RAL: 'RAL 8003',
  HEX: '#734222'
}, {
  RAL: 'RAL 8004',
  HEX: '#8E402A'
}, {
  RAL: 'RAL 8007',
  HEX: '#59351F'
}, {
  RAL: 'RAL 8008',
  HEX: '#6F4F28'
}, {
  RAL: 'RAL 8011',
  HEX: '#5B3A29'
}, {
  RAL: 'RAL 8012',
  HEX: '#592321'
}, {
  RAL: 'RAL 8014',
  HEX: '#382C1E'
}, {
  RAL: 'RAL 8015',
  HEX: '#633A34'
}, {
  RAL: 'RAL 8016',
  HEX: '#4C2F27'
}, {
  RAL: 'RAL 8017',
  HEX: '#45322E'
}, {
  RAL: 'RAL 8019',
  HEX: '#403A3A'
}, {
  RAL: 'RAL 8022',
  HEX: '#212121'
}, {
  RAL: 'RAL 8023',
  HEX: '#A65E2E'
}, {
  RAL: 'RAL 8024',
  HEX: '#79553D'
}, {
  RAL: 'RAL 8025',
  HEX: '#755C48'
}, {
  RAL: 'RAL 8028',
  HEX: '#4E3B31'
}, {
  RAL: 'RAL 8029',
  HEX: '#763C28'
}, {
  RAL: 'RAL 9001',
  HEX: '#FDF4E3'
}, {
  RAL: 'RAL 9002',
  HEX: '#E7EBDA'
}, {
  RAL: 'RAL 9003',
  HEX: '#F4F4F4'
}, {
  RAL: 'RAL 9004',
  HEX: '#282828'
}, {
  RAL: 'RAL 9005',
  HEX: '#0A0A0A'
}, {
  RAL: 'RAL 9006',
  HEX: '#A5A5A5'
}, {
  RAL: 'RAL 9007',
  HEX: '#8F8F8F'
}, {
  RAL: 'RAL 9010',
  HEX: '#FFFFFF'
}, {
  RAL: 'RAL 9011',
  HEX: '#1C1C1C'
}, {
  RAL: 'RAL 9016',
  HEX: '#F6F6F6'
}, {
  RAL: 'RAL 9017',
  HEX: '#1E1E1E'
}, {
  RAL: 'RAL 9018',
  HEX: '#CFD3CD'
}, {
  RAL: 'RAL 9022',
  HEX: '#9C9C9C'
}, {
  RAL: 'RAL 9023',
  HEX: '#828282'
}]

export default color
