var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container py-5"},[_vm._m(0),_c('div',{staticClass:"container"},_vm._l((_vm.reviews),function(review){return _c('div',{key:review.id,staticClass:"py-5"},[_c('vueper-slides',{attrs:{"arrows-outside":"","bullets-outside":"","slide-ratio":2 / 3,"visible-slides":2,"gap":3,"breakpoints":{
        1200: {
          visibleSlides: 1,
          slideRatio: 2 / 1.41
        },
        700: {
          visibleSlides: 1,
          slideRatio: 2 / 1.41,
          arrowsOutside: false
        }
      }}},_vm._l((review.imgs),function(img,i){return _c('vueper-slide',{key:i,attrs:{"image":img}})}),1)],1)}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"featurette mx-auto row justify-center text center py-2"},[_c('h2',{staticClass:"featurette-heading"},[_vm._v("Клиенты о нас")])])
}]

export { render, staticRenderFns }