<template>
  <div class="text-secondary px-4 py-3 text-center bg-dark">
  <div class="container col-xl-10 col-xxl-8 px-4">
    <div class="row align-items-center g-lg-5 py-5">
      <div class="col-lg-7 text-center text-lg-start">
        <h3 class="display-4 fw-bold lh-1 mb-3 text-white">{{title}}</h3>
        <p class="col-lg-10 fs-4 lead">{{description}}</p>
      </div>
      <div class="col-md-10 mx-auto col-lg-5">
        <form
        ref="contact"
        class="p-4 p-md-5 border bg-white">
          <input type="hidden" name="type" value="contact_form">
          <div class="form-floating mb-3">
            <input type="text" class="form-control form-control-sm" id="inputHeroName" :placeholder="formPlaceholders.name" name="name">
            <label for="inputHeroName">{{formPlaceholders.name}}</label>
          </div>
          <div class="form-floating mb-3">
            <input
            v-model="contact"
            type="text"
            class="form-control"
            id="inputHeroPhone"
            :placeholder="formPlaceholders.contact"
            required
            name="contact">
            <label for="inputHeroPhone">{{formPlaceholders.contact}}</label>
          </div>
          <div class="mb-3">
            <input
              ref="file"
              class="visually-hidden"
              type="file" id="formFileMultiple"
              name="myfile"
              accept="image/*, video/*, text/plain, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/pdf, .xml, .json" @change="changeFileName"
            >
            <label class="label-for-file" for="formFileMultiple" style="cursor: pointer">{{fileName}}</label>
          </div>
          <Alert
          v-if="alert"
          :status="status"/>
          <button
            :disabled="contact.length < 5 || sending"
            @click.prevent="submitForm"
            type="submit"
            class="w-100 btn btn-lg"
            :class="contact && contact.length >= 5 ? 'btn-primary' : 'btn-secondary'"
            >
            {{contact && contact.length >= 5 ? formPlaceholders.submit : 'Для отправки заполните контактную форму'}}</button>
          <hr class="my-4">
          <small class="text-muted">Нажимая кнопку <b>'отправить'</b> вы соглашаетесь с нашей <router-link
              to="/politika" target="_blank">Политикой конфиденциальности</router-link></small>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Alert from './Alert.vue'

export default {
  name: 'ContactForm',
  components: {
    Alert
  },

  data () {
    return {
      contact: '',
      sending: false,
      status: '',
      alert: false,
      title: 'Оставьте заявку',
      description: 'А лучше позвоните нам, ведь мы стали забывать, что такое живое общение 😉 Мы исчерпывающе ответим на все Ваши вопросы',
      formPlaceholders: {
        name: 'Ваше имя',
        contact: 'Телефон или почта',
        submit: 'Отправить'
      },
      fileName: 'Приложите файл'
    }
  },

  methods: {
    changeFileName () {
      this.fileName = this.$refs.file.files[0].name
    },
    submitForm: async function () {
      this.sending = true
      const response = await fetch('/mailer/send.php', {
        method: 'POST',
        body: new FormData(this.$refs.contact)
      })

      if (response.status === 200) {
        this.$refs.contact.reset()
        this.status = 'success'
        this.alert = true
        this.contact = ''
        this.fileName = 'Приложите файл'
        this.$refs.file.value = ''
        setTimeout(() => {
          this.status = ''
          this.alert = false
        }, 3000)
      } else {
        this.status = 'error'
        this.alert = true
        setTimeout(() => {
          this.status = ''
          this.alert = false
        }, 3000)
      }
      this.sending = false
    }
  }

}
</script>

<style>
  .contact-form {
    background: linear-gradient(45deg, black, white);
  }
  .label-for-file {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 16px 12px;
    text-align: left;
  }
</style>
