<template>
  <main>
    <AboutCompany/>

    <OurAdvantages/>

    <!-- <Questions/> -->
  </main>
</template>

<script>
import AboutCompany from '@/components/AboutCompany.vue'
import OurAdvantages from '@/components/OurAdvantages.vue'
// import Questions from '@/components/Questions.vue'

export default {
  name: 'Home',
  components: {
    AboutCompany,
    OurAdvantages
    // Questions
  }
}
</script>
