<template>
  <div class="container py-5">
    <div class="featurette mx-auto row justify-center text center py-2">
      <h2 class="featurette-heading">Клиенты о нас</h2>
    </div>
    <div class="container">
      <div
        v-for="review in reviews"
        :key="review.id"
        class=" py-5"
      >
      <vueper-slides
        arrows-outside
        bullets-outside
        :slide-ratio="2 / 3"
        :visible-slides="2"
        :gap="3"
        :breakpoints="{
          1200: {
            visibleSlides: 1,
            slideRatio: 2 / 1.41
          },
          700: {
            visibleSlides: 1,
            slideRatio: 2 / 1.41,
            arrowsOutside: false
          }
        }"
      >
        <vueper-slide
        v-for="(img, i) in review.imgs"
        :key="i"
        :image="img"/>
      </vueper-slides>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'OurReviews',
  components: {
    VueperSlides, VueperSlide
  },
  data () {
    return {
      reviews: [
        {
          title: 'Наши клиенты ',
          subtitle: 'о нас',
          description: '',
          imgs: [
            require('../assets/img/otzyvy/1.webp'),
            require('../assets/img/otzyvy/2.webp'),
            require('../assets/img/otzyvy/3.webp')
          ],
          id: 1
        }
      ]
    }
  }
}
</script>
